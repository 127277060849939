/* eslint-disable */
const messages = {
  _default(e) {
    return `O valor do campo ${e} não é válido.`;
  },
  after(e, n) {
    return `O campo ${e} deve estar depois do campo ${n[0]}.`;
  },
  alpha(e) {
    return `O campo ${e} deve conter somente letras.`;
  },
  alpha_dash(e) {
    return `O campo ${e} deve conter letras, números e traços.`;
  },
  alpha_num(e) {
    return `O campo ${e} deve conter somente letras e números.`;
  },
  alpha_spaces(e) {
    return `O campo ${e} só pode conter caracteres alfabéticos e espaços.`;
  },
  before(e, n) {
    return `O campo ${e} deve estar antes do campo ${n[0]}.`;
  },
  between(e, n) {
    return `O campo ${e} deve estar entre ${n[0]} e ${n[1]}.`;
  },
  confirmed(e, n) {
    return `Os campos ${e} e ${n[0]} devem ser iguais.`;
  },
  credit_card(e) {
    return `O campo ${e} é inválido.`;
  },
  date_between(e, n) {
    return `O campo ${e} deve estar entre ${n[0]} e ${n[1]}.`;
  },
  date_format(e, n) {
    return `O campo ${e} deve estar no formato ${n[0]}.`;
  },
  decimal(e, n) {
    void 0 === n && (n = []);
    let o = n[0];
    return void 0 === o && (o = ''), `O campo ${e} deve ser numérico e deve conter ${o && o !== '' ? o : ''} casas decimais.`;
  },
  digits(e, n) {
    return `O campo ${e} deve ser numérico e ter exatamente ${n[0]} dígitos.`;
  },
  dimensions(e, n) {
    return `O campo ${e} deve ter ${n[0]} pixels de largura por ${n[1]} pixels de altura.`;
  },
  email(e) {
    return `O campo ${e} deve ser um email válido.`;
  },
  excluded(e) {
    return `O campo ${e} deve ser um valor válido.`;
  },
  ext(e) {
    return `O campo ${e} deve ser um arquivo válido.`;
  },
  image(e) {
    return `O campo ${e} deve ser uma imagem.`;
  },
  included(e) {
    return `O campo ${e} deve ter um valor válido.`;
  },
  integer(e) {
    return `O campo ${e} deve ser um número inteiro.`;
  },
  ip(e) {
    return `O campo ${e} deve ser um endereço IP válido.`;
  },
  is(e) {
    return `O valor inserido no campo ${e} não é válido.`;
  },
  length(e, n) {
    const o = n[0];
    const r = n[1];
    return r ? `O tamanho do campo ${e} está entre ${o} e ${r}.` : `O tamanho do campo ${e} deve ser ${o}.`;
  },
  max(e, n) {
    return `O campo ${e} não deve ter mais que ${n[0]} caracteres.`;
  },
  max_value(e, n) {
    return `O campo ${e} precisa ser ${n[0]} ou menor.`;
  },
  mimes(e) {
    return `O campo ${e} deve ser um tipo de arquivo válido.`;
  },
  min(e, n) {
    return `O campo ${e} deve conter pelo menos ${n[0]} caracteres.`;
  },
  min_value(e, n) {
    return `O campo ${e} precisa ser ${n[0]} ou maior.`;
  },
  numeric(e) {
    return `O campo ${e} deve conter apenas números`;
  },
  regex(e) {
    return `O campo ${e} possui um formato inválido.`;
  },
  required(e) {
    return `O campo ${e} é obrigatório.`;
  },
  size(e, n) {
    return `O campo ${e} deve ser menor que ${(function (e) {
      const n = 1024;
      const o = (e = Number(e) * n) === 0 ? 0 : Math.floor(Math.log(e) / Math.log(n));
      return `${1 * (e / Math.pow(n, o)).toFixed(2)} ${['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][o]}`;
    }(n[0]))}.`;
  },
  url(e) {
    return `O campo ${e} não é uma URL válida.`;
  },
};

const locale = {
  name: 'id',
  messages,
  attributes: {},
};

export default locale;
